<div class="slides" #slidesContainer>
  <div *ngFor="let slide of slides; let i = index" class="slide" [ngClass]="{'show': currentSlideIndex === i}" style="background-color: #FDF9F6" id="slide{{i}}" [ngStyle]="{'padding-top': (i === 0 || i === 6) ? '0px' : '30px'}">
    <button class="close-button" (click)="closeSlide(i)">
      <img src="../../../assets/slide/Cross.svg" alt="Close">
    </button>
    <div *ngIf="i !== 0 && i !== slides.length - 1" [innerHTML]='slide.subtitle' class="slide-subtitle"></div>
    <div class="slide-title" 
    [ngStyle]="{
      'font-size': isItMobile ? 
        (i === 0 || i === 6 ? '28px' : '22px') : 
        (i === 0 || i === 6 ? 'clamp(2rem, 3.33vw, 4rem)' : 'clamp(1.5rem, 2.396vw, 2.875rem)')
    }" 
    [innerHTML]="slide.title">
</div>
   <!-- <div *ngIf="(i === currentSlideIndex) && showWarning" class="warning"> Please select an answer</div> -->
    <div *ngIf="i === 0 || i === slides.length - 1" [innerHTML]='slide.subtitle' class="slide-subtitle"></div>
    <div *ngIf="slide.buttons && slide.buttons.length && i !== 4" class="button-grid">
      <button *ngFor="let button of slide.buttons; let j = index"
              (click)="selectButton(i, j)"
              [ngClass]="{'active': selectedButtonIndexes[i] === j}">
        {{ button.label }}
      </button>
    </div>
    <div *ngIf="i === 4" class="contact-inputs">
      <input type="text" placeholder="Your Name" [(ngModel)]="name" #nameInput="ngModel" required [ngClass]="{ 'invalid-input': nameInput.invalid && (nameInput.dirty || submitted) }">
      <input type="text" placeholder="Company Name" [(ngModel)]="company" #companyInput="ngModel" required [ngClass]="{ 'invalid-input': companyInput.invalid && (companyInput.dirty || submitted) }">
      <input type="text" placeholder="Your Email" [(ngModel)]="email" #emailInput="ngModel" required [ngClass]="{ 'invalid-input': emailInput.invalid && (emailInput.dirty || submitted) }">
      <!-- <input type="text" placeholder="Phone Number" [(ngModel)]="phone" #phoneInput="ngModel" required [ngClass]="{ 'invalid-input': phoneInput.invalid && (phoneInput.dirty || submitted) }"> -->

    </div>
   
    <button class="next-button" (click)="scrollToNextSlide(i)">
      <img class="next-image" src="../../../assets/slide/next-button.png" alt="Next">
    </button>
     <mat-progress-spinner  *ngIf="i === 5 && isLoading" style="margin-top: 100px;" mode="indeterminate" color="primary"></mat-progress-spinner> 
    <!-- <div class="submitting" *ngIf="i === 4">
      By submitting this form, you will receive information, tips, and promotions <br> from Elevate Social. To learn more, see our Privacy Statement.
    </div> -->
  </div>
</div>
