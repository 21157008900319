
<div appLazyLoad >

  <div
    class="example-container" 
    [class.example-is-mobile]="mobileQuery.matches"
  >
  <mat-toolbar *ngIf="receivedData !== 'close'"  matRippleDisabled  color="primary" class="example-toolbar">
    <button [style.display]="snav.opened ? 'none' : 'block'" mat-icon-button class="mat-icon-button" (click)="snav.toggle()" aria-label="Toggle side menu">
      <img height="17.35" width="17.35" src="../assets/sideMenu.svg" alt="Toggle side menu">
  </button>
  
      
    </mat-toolbar>
    <mat-sidenav-container
      class="example-sidenav-container"
      [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
    >
      <mat-sidenav style="background-color: #020D10 !important;"
        #snav
        [mode]="mobileQuery.matches ? 'over' : 'side'"
        [fixedInViewport]="mobileQuery.matches"
        fixedTopGap="0"
        [style]="mobileQuery.matches?  'width:100%':'382px'"
      >
      <mat-nav-list>
        <div  (click)="snav.toggle()" class="navClose">
          <img width="21" height="21" alt="Cross logo to nav close" src="../../../../assets/Home/Cross.webp" />
        </div>
        <div  class="navLogo">
        </div>
        <span  *ngFor="let item of menu; index as i">
          <span *ngIf="item.children && item.children.length > 0" >
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      {{item.displayName}}
                                       </div>
                  
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <span *ngFor="let child of item.children">
                  <mat-list-item (click)="navigateToSection(i+1)" routerLinkActive="active">
                    <div class="navTitle" fxLayout="row" fxLayoutAlign="space-between center">
                      {{child.displayName}}
                       </div>
                  </mat-list-item>
                </span>
              </mat-expansion-panel>
            </mat-accordion>
          </span>
          
          <span  *ngIf="!item.children || item.children.length === 0 ;">
            <mat-list-item (click)="navigateToSection(i+1)" routerLinkActive="active">
              <div class="navTitle" fxLayout="row" fxLayoutAlign="space-between center">
                {{item.displayName}}
              </div>
             
            </mat-list-item>
          </span>
         
        </span>
       <div (click)="this.navigateTo('survey','0')" class="navTitle" style="margin-left: 15px; "> Book a call <span class="free-label" >Free</span></div> 
      </mat-nav-list>
      </mat-sidenav>
        <mat-sidenav-content *ngIf="!showContact" >
           <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  
  
  <app-contact (dismiss)="dismissBlurryComponent()" *ngIf="showContact"></app-contact>
  </div>
