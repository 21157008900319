import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactComponent } from '../pages/Home-flow/contact/contact.component';
import { OrderConfirmationComponent } from '../pages/order-confirmation/order-confirmation.component';
import { AdminLoginComponent } from '../admin/login/admin-login.component';
import { SearchOverlayComponent } from '../pages/Home-flow/search-overlay/search-overlay.component';
import { CalendlyComponent } from '../pages/Calendly/calendly/calendly.component';
import { PaymentGeneratorComponent } from '../admin/payment-generator/payment-generator.component';
import { SlideshowQuestionsComponent } from '../pages/slideshow-quastions/slideshow-quastions.component';
import { BookCalenderComponent } from '../pages/book-calender/book-calender.component';



const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../main-screen/main-screen.module').then(m => m.MainScreenModule)
  },

  { 
    path: 'pricing', 
    loadChildren: () => import('../../app/pricing-table/pticing-table.module').then(m => m.PricingTableModule) 
  },
  { 
    path: 'articles/:title', 
    loadChildren: () => import('../../app/pages/blog/blog-details/blog-details.module').then(m => m.BlogDetailsModule) 
  },
  { 
    path: 'resources', 
    loadChildren: () => import('../../app/pages/blog/blog.module').then(m => m.BlogModule) 
  },
  { 
    path: 'guarentee', 
    loadChildren: () => import('../../app/pages/guarentee/guarentee.module').then(m => m.GuarenteeModule) 
  },
  { 
    path: 'privacy', loadChildren: () => import('../../app/pages/privacy-policy/privacy-policy-routing.module').then(m => m.PrivacyPolicyModule) 
  },
  { 
    path: 'terms', loadChildren: () => import('../../app/pages/terms-of-service/terms-of-service.module').then(m => m.TermsOfServiceModule) 
  },
  { path: 'contact', component: ContactComponent },
  { path: 'done', component: OrderConfirmationComponent },
  { path: 'admin', component: AdminLoginComponent },
  { path: 'search', component:SearchOverlayComponent },
  { path: 'book-your-call', component: CalendlyComponent },
  { path: 'registration', component: PaymentGeneratorComponent },
  { path: 'book', component: BookCalenderComponent },
  { path: 'survey', component:  SlideshowQuestionsComponent},
  { path: '**', redirectTo: '' } ,
];



@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'disabled', // or 'top' or 'bottom' or 'nearest'
      anchorScrolling: 'disabled',
      scrollOffset: [0, 64], // Adjust as needed
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
